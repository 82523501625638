<!--  -->
<template>
  <div>
    <!-- header 导航栏 -->
    <div class="nav" >
      <div
        style="
          width: 233px;
          height: 80px;
        
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
   
          <img src="../assets/zkImages/zk/矢量智能对象@2x.png" alt="" style="width:180px;height:40px">
      
        <!-- <div
          style="
            margin-left: 10px;
            font-weight: 400;
            color: #fff;
            font-size: 20px;
          "
        >
          博爱医药
        </div> -->
      </div>
     <div>
      <ul class="center">
        <li>
          <a :class="{ active: itemIndex == 1 }" 
            @click="btnClick(1)">医院首页</a>
        </li>
        <li  @click="btnClick(2)">
          <a :class="{ active: itemIndex == 2 }">医院简介</a>
        </li>
        <li @click="btnClick(3)">
          <a :class="{ active: itemIndex == 3 }">来院路线</a> |
        </li>
        <!-- <li @click="btnClick(4)">
          <a :class="{ active: itemIndex == 4 }">博爱大事记</a>|
        </li>
        <li @click="btnClick(5)">
          <a :class="{ active: itemIndex == 5 }">供应商平台</a>|
        </li>
        <li @click="btnClick(6)">
          <a :class="{ active: itemIndex == 6 }">人才招聘</a>|
        </li> -->

        <li>
          <a href="#"
            ><img
              src="../assets/web/sousuo.png"
              alt=""
              style="width: 12px; height: 12px; margin-left: 10px"
          /></a>
        </li>
        <li>
          <a href="#"
            ><img
              src="../assets/web/phone.png"
              alt=""
              style="width: 12px; height: 12px"
            />&nbsp;&nbsp;&nbsp; 0417-5675777</a
          >
        </li>
      </ul>
     </div>
    </div>
    <!-- 1是首页  2是关于我们 3是会员服务 4是博爱大事记 5是供应商平台 6是人才招聘-->
    <div v-if="this.itemIndex == 1">
      <!-- banner -->
      <div class="top-rowBanner"></div>
      <!-- 关于博爱 -->
      
      <!-- 四大优势 -->
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
          "
        >
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #0762cb;
             
             padding:100px 0;
            "
          >
            <img src="../assets/zkImages/zk/Hospital Introduction.png" alt="" style="height:90px">
          </div>
    
 
        </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
         <img src="../assets/zkImages/zk/图层 32@2x.png" alt="" style="width:1000px;">
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
            
          "
        >
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #0762cb;
             
             padding:100px 0;
            "
          >
            <img src="../assets/zkImages/zk/Hospital environment@2x.png" alt="" style="height:90px">
          </div>
    
 
        </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content: center;
            align-items: center;

          "
        >
         <img src="../assets/zkImages/zk/图层 41@2x.png" alt="" style="width:1000px;margin-top:180px">
        </div>
      </div>
     

     
      <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:260px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>医院首页</div>
        <div>医院简介</div>
        <div>来院路线</div>
        
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
        <div>联系电话：0417-5675777</div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦四楼</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
        <div style="margin-top:10px">备案号：辽ICP备20007425号-1</div>
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <!-- <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;"> -->
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 2">
      <!-- banner -->
      <div class="top-rowBanner"></div>
      <!-- 关于博爱 -->
      
      <!-- 四大优势 -->
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
          "
        >
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #0762cb;
             
             padding:100px 0 10px 0;
            "
          >
            <img src="../assets/zkImages/zk/Hospital Introduction.png" alt="" style="height:90px">
          </div>
    
 
        </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content:space-between;
            align-items: center;
          "
        >
       <img src="../assets/zkImages/zk/医生团队@2x.png" alt="" style="width:200px;height:80px">
       <img src="../assets/zkImages/zk/家庭式服务@2x.png" alt="" style="width:200px;height:80px">
       <img src="../assets/zkImages/zk/爱在，健康在@2x.png" alt="" style="width:200px;height:80px">
        </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
         <img src="../assets/zkImages/zk/图层 32@2x.png" alt="" style="width:1000px;">
        </div>
      </div>
      <!-- 品质服务 -->
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
            
          "
        >
        
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #0762cb;
             
             padding:100px 0 70px  0;
            "
          >
            <img src="../assets/zkImages/zk/Quality service@2x.png" alt="" style="height:90px">
          </div>
   
        </div>
        <!-- 贴心服务  私人健康管家服务   一对一接诊模式 -->
  <div style="width:600px;height:27px;color:#9F9F9F;font-size:19px;display:flex;justify-content:space-between">
  <div :class="{activeShowHos:showtable==1}"  @click="btnshowtable(1)">贴心服务</div>
  <div :class="{activeShowHos:showtable==2}"  @click="btnshowtable(2)">私人健康管家服务</div>
  <div :class="{activeShowHos:showtable==3}"  @click="btnshowtable(3)">一对一接诊模式</div>
  </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content: center;
            align-items: center;

          "
        >
        
         <img v-if="showtable==1" src="../assets/zkImages/zk/① ② ③ ④ ⑤.png" alt="" style="width:1000px;margin-top:200px">
         <img v-if="showtable==2" src="../assets/zkImages/zk/siren.png" alt="" style="width:1000px;margin-top:200px">
         <img v-if="showtable==3" src="../assets/zkImages/zk/“一医一患一诊室”，保障您的隐私，为您提供良好、安静的就诊环境，资深医师与您一对耐心沟通、深度评估，让您全方面了解自身健康状况.png" alt="" style="width:1000px;margin-top:200px">
        </div>
      </div>
<!-- 医院环境 -->
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top:130px
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
            
          "
        >
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #0762cb;
             
             padding:100px 0;
            "
          >
            <img src="../assets/zkImages/zk/Hospital environment@2x.png" alt="" style="height:90px">
          </div>
    
 
        </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content: center;
            align-items: center;

          "
        >
         <img src="../assets/zkImages/zk/图层 41@2x.png" alt="" style="width:1000px;margin-top:180px">
        </div>
      </div>
     

     
      <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:260px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>医院首页</div>
        <div>医院简介</div>
        <div>来院路线</div>
        
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
        <div>联系电话：0417-5675777</div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦四楼</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
        <div style="margin-top:10px">备案号：辽ICP备20007425号-1</div>
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <!-- <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;"> -->
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 3">
      <!-- banner -->
      <div class="top-rowBanner"></div>
      <!-- 关于博爱 -->
      
      <!-- 四大优势 -->
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
          "
        >
      
    
 
        </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top:100px
          "
        >
         <img src="../assets/zkImages/zk/map.png" alt="" style="width:1000px;">
        </div>
      </div>
  
     

     
      <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:100px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>医院首页</div>
        <div>医院简介</div>
        <div>来院路线</div>
        
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
        <div>联系电话：0417-5675777</div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦四楼</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
        <div style="margin-top:10px">备案号：辽ICP备20007425号-1</div>
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <!-- <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;"> -->
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 4">
      
        <div class="top-rowBanner544444"></div>
            <!-- 关于我们导航栏 公司概况 品质服务 企业荣誉 企业文化 -->
            <div class="top-rowBanner2222" style="display:flex;justify-content:center;align-items:center">
         <div style="width:1000px;height:80px;display:flex;align-items:center;justify-content:space-between">
          <div class="left" style="display:flex">
          <div style="width:78px;height:90px;background:#0762CB;margin-top:-9px;display:flex;align-items:center;justify-content:space-around;flex-direction:column">
            <div></div>
            <img src="../assets/web/c.png" alt="" style="width:54px;height:18px">
            <div style="color:#6A93D9;font-size:15px;font-weight:600">博爱大事记</div>
            <div></div>
          </div>
          <div style="display:flex;margin-top:30px;justify-content:center;margin-left:30px">
            <img src="../assets/web/sjx.png" alt="" style="width:8px;height:16px;margin-top:2px"> 
         
           <div v-if="this.itemIndexba == 1"  style="margin-left:7px">博爱大事记  </div>
           <!-- <div v-if="this.itemIndexVip == 2"  style="margin-left:7px">慢病中心  </div>
           <div v-if="this.itemIndexVip == 3"  style="margin-left:7px">老中医咨询</div> -->
           <!-- <div v-if="this.itemIndexVip == 4"  style="margin-left:7px">企业文化</div> -->
          </div>

          </div>
          <div class="right" style="display:flex;width:400px;justify-content:space-between">
            <div></div>
            <div></div>
            <div></div>
            <div :class="{ activeAbout:  itemIndexba == 1  }"  @click="btnClickba(1)">博爱大事记 </div>
            <!-- <div :class="{ activeAbout:  itemIndexVip == 2  }"  @click="btnClickVip(2)">慢病中心  </div>
            <div  :class="{ activeAbout: itemIndexVip == 3 }"   @click="btnClickVip(3)">老中医咨询</div> -->
            <!-- <div  :class="{ activeAbout: itemIndexVip == 4 }"   @click="btnClickVip(4)">企业文化</div> -->
          </div>
         </div>
        </div>
        <div v-if="itemIndexba==1" style="display:flex;justify-content:center;align-items:center">
           <div style="width:1000px;padding:35px 0;display:flex;justify-content:center;flex-direction:column;"> 
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2001年</div>
            <div style="color:#000;font-size:19px"   >博爱大药房成立第一家门店</div>
           </div>
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2002年-2015年</div>
            <div style="color:#000;font-size:19px"   >陆续开展连锁加盟200余家门店</div>
           </div>
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2015年</div>
            <div style="color:#000;font-size:19px"   >与第三方平台接洽开展医药电商业务</div>
           </div>
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2019年</div>
            <div style="color:#000;font-size:19px"   >开发自主平台电商新零售业务</div>
           </div>
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2020年</div>
            <div style="color:#000;font-size:19px"   >建设开发中国医药电商小镇</div>
           </div>
           <div style="width:100%;padding:40px 0  20px 0;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:21px;font-weight:600">联系我们</div>
            <div style="color:#000;"   >
              <div style="font-size:22px">营口博爱医药连锁有限公司</div>
            <br/>
            <div style="margin-top:16px">联系电话：0417-5675777</div>
            
            <div  style="margin-top:8px">地址：中国·辽宁·大石桥博爱大厦总部</div>
            
            <div  style="margin-top:8px">工作时间：周一至周六08:00-17:00</div>
            </div>
            <div style="color:#000;font-size:19px;display:flex"   >
            
            <div>
              <img src="../assets/web/baewm.png" alt="" style="width:100px;height:100px">
              <div>博爱医药馆</div>
            </div>
            <div>
              <img src="../assets/web/gzh.png" alt="" style="width:100px;height:100px">
              <div>博爱公众号</div>
            </div>
            </div>
           </div>
        

           </div>
        </div>
        <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:20px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
        <div>联系电话：0417-5675777</div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 5">
        <!-- banner -->
        <div class="top-rowBanner5555"></div>
            <!-- 关于我们导航栏 公司概况 品质服务 企业荣誉 企业文化 -->
            <div class="top-rowBanner2222" style="display:flex;justify-content:center;align-items:center">
         <div style="width:1000px;height:80px;display:flex;align-items:center;justify-content:space-between">
          <div class="left" style="display:flex">
          <div style="width:78px;height:90px;background:#0762CB;margin-top:-9px;display:flex;align-items:center;justify-content:space-around;flex-direction:column">
            <div></div>
            <img src="../assets/web/d.png" alt="" style="width:54px;height:18px">
            <div style="color:#6A93D9;font-size:15px;font-weight:600">供应商平台</div>
            <div></div>
          </div>
          <div style="display:flex;margin-top:30px;justify-content:center;margin-left:30px">
            <img src="../assets/web/sjx.png" alt="" style="width:8px;height:16px;margin-top:2px"> 
           
           <div v-if="this.itemIndexSup == 1"  style="margin-left:7px">送货须知  </div>
           <div v-if="this.itemIndexSup == 2"  style="margin-left:7px">投资联盟</div>
           <div v-if="this.itemIndexSup == 3"  style="margin-left:7px">联系我们</div>
           <!-- <div v-if="this.itemIndexVip == 4"  style="margin-left:7px">企业文化</div> -->
          </div>

          </div>
          <div class="right" style="display:flex;width:400px;justify-content:space-between">
            <div></div>
            <div :class="{ activeAbout:  itemIndexSup == 1  }"  @click="btnClickSup(1)">送货须知  </div>
            <div :class="{ activeAbout:  itemIndexSup == 2  }"  @click="btnClickSup(2)">投资加盟 </div>
            <div  :class="{ activeAbout: itemIndexSup == 3 }"   @click="btnClickSup(3)">联系我们</div>
            <!-- <div  :class="{ activeAbout: itemIndexVip == 4 }"   @click="btnClickVip(4)">企业文化</div> -->
          </div>
         </div>
        </div>
         <!-- 送货须知 -->
        <div v-if="this.itemIndexSup == 1" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
        <div style="width:1000px;">
          <div style="color:#0762CB;font-size:22px;padding:40px 0 20px 0;">博 爱 医 药 供 应 商 送 货 须 知</div>
        <img src="../assets/web/supword1.png" alt="" style="width:100%">
        </div>
        
        
        </div>
        <!-- 投资加盟-->
        <div v-if="this.itemIndexSup== 2" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
          <div style="width:1000px;">
          <div style="color:#0762CB;font-size:22px;padding:40px 0 20px 0;">投 资 加 盟</div>
        <img src="../assets/web/tzjm.png" alt="" style="width:100%">
        </div>
        </div>
        <!-- 联系我们 -->
        <div v-if="this.itemIndexSup == 3" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
          <div style="width:1000px;">
           
            <div style="width:100%;padding:40px 0  20px 0;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:21px;font-weight:600">联系我们</div>
            <div style="color:#000;"   >
              <div style="font-size:22px">营口博爱医药连锁有限公司</div>
            <br/>
            <div style="margin-top:16px">联系电话：0417-5675777</div>
            
            <div  style="margin-top:8px">地址：中国·辽宁·大石桥博爱大厦总部</div>
            
            <div  style="margin-top:8px">工作时间：周一至周六08:00-17:00</div>
            </div>
            <div style="color:#000;font-size:19px;display:flex"   >
            
            <div>
              <img src="../assets/web/baewm.png" alt="" style="width:100px;height:100px">
              <div>博爱医药馆</div>
            </div>
            <div>
              <img src="../assets/web/gzh.png" alt="" style="width:100px;height:100px">
              <div>博爱公众号</div>
            </div>
            </div>
           </div>
           <div>
            <img src="../assets/web/map.png" alt="" style="width:100%;margin-top:30px">
           </div>

</div>

      
        </div>
       
    
        <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:50px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
        <div>联系电话：0417-5675777</div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 6">
        <!-- banner -->
        <div class="top-rowBanner6666"></div>
            <!-- 关于我们导航栏 公司概况 品质服务 企业荣誉 企业文化 -->
            <div class="top-rowBanner2222" style="display:flex;justify-content:center;align-items:center">
         <div style="width:1000px;height:80px;display:flex;align-items:center;justify-content:space-between">
          <div class="left" style="display:flex">
          <div style="width:78px;height:90px;background:#0762CB;margin-top:-9px;display:flex;align-items:center;justify-content:space-around;flex-direction:column">
            <div></div>
            <img src="../assets/web/e.png" alt="" style="width:54px;height:18px">
            <div style="color:#6A93D9;font-size:16px;font-weight:600">人才招聘</div>
            <div></div>
          </div>
          <div style="display:flex;margin-top:30px;justify-content:center;margin-left:30px">
            <img src="../assets/web/sjx.png" alt="" style="width:8px;height:16px;margin-top:2px"> 
           
           <div v-if="this.itemIndexzp == 1"  style="margin-left:7px">社会招聘 </div>
           <div v-if="this.itemIndexzp == 2"  style="margin-left:7px">员工风采  </div>
           <!-- <div v-if="this.itemIndexVip == 3"  style="margin-left:7px">老中医咨询</div> -->
           <!-- <div v-if="this.itemIndexVip == 4"  style="margin-left:7px">企业文化</div> -->
          </div>

          </div>
          <div class="right" style="display:flex;width:400px;justify-content:space-between">
            <div></div>
            <div></div>
            <div :class="{ activeAbout:  itemIndexzp == 1  }"  @click="btnClickzp(1)">社会招聘  </div>
            <div :class="{ activeAbout:  itemIndexzp == 2  }"  @click="btnClickzp(2)">员工风采  </div>
            <!-- <div  :class="{ activeAbout: itemIndexVip == 3 }"   @click="btnClickVip(3)">老中医咨询</div> -->
            <!-- <div  :class="{ activeAbout: itemIndexVip == 4 }"   @click="btnClickVip(4)">企业文化</div> -->
          </div>
         </div>
        </div>
         <!-- 社会招聘 -->
        <div v-if="this.itemIndexzp == 1" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
        <div style="width:1000px;background">
          <div style="color:#000;font-size:26px;padding:40px 0 20px 0;">基础信息
          </div>
          <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between">
  <el-form-item label="姓名" required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px" placeholder="请输入姓名"></el-input>
  </el-form-item>
  <el-form-item label="身份证号" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入身份证号"></el-input>
  </el-form-item>
  <el-form-item label="手机号" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入手机号" ></el-input>
  </el-form-item>
  
</el-form>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="性别" required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px" placeholder="请输入性别"></el-input>
  </el-form-item>
  <el-form-item label="户口类型" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入户口类型"></el-input>
  </el-form-item>
  <el-form-item label="居住地址" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入居住地址"></el-input>
  </el-form-item>
  
</el-form>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="最高学历" required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px" placeholder="请输入最高学历"></el-input>
  </el-form-item>
  <el-form-item label="毕业院校" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入院校"></el-input>
  </el-form-item>
  <el-form-item label="所学专业" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入所学专业"></el-input>
  </el-form-item>
  
</el-form>
<div style="color:#000;font-size:26px;padding:40px 0 20px 0;">求职状态</div>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="应聘职位" required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px;" placeholder="请输入应聘职位" ></el-input>
  </el-form-item>
  <el-form-item label="期望薪资" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入期望薪资"></el-input>
  </el-form-item>
  <el-form-item label="期望地点" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入期望地点"></el-input>
  </el-form-item>
  
</el-form>
<div style="color:#000;font-size:26px;padding:40px 0 20px 0;">工作经历</div>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="公司名称"  required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px;" placeholder="请输入公司名称" ></el-input>
  </el-form-item>
  <el-form-item label="职位名称" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入职位名称"></el-input>
  </el-form-item>
  <el-form-item label="在职时间" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入在职时间"></el-input>
  </el-form-item>
  
</el-form>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="工作职责" required=“true” >
  <textarea name="1" id="" cols="30" rows="10" style="width:1000px;height:100px" placeholder="请输入工作职责"></textarea>
  </el-form-item>
  
  
</el-form>
<div style="color:#000;font-size:26px;padding:40px 0 20px 0;">项目经历</div>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="项目名称" required=“true” >
    <el-input v-model="formLabelAlign.name" style="width:300px;" placeholder="请输入项目名称" ></el-input>
  </el-form-item>
  <el-form-item label="担任角色" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入担任角色"></el-input>
  </el-form-item>
  <el-form-item label="项目周期" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入项目周期"></el-input>
  </el-form-item>
  
</el-form>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="项目描述" required=“true”>
  <textarea name="1" id="" cols="30" rows="10" style="width:1000px;height:100px" placeholder="请输入项目描述"></textarea>
  </el-form-item>
  
  
</el-form>
<div style="color:#000;font-size:26px;padding:40px 0 20px 0;">自我评价</div>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="评价内容" required=“true”>
  <textarea name="1" id="" cols="30" rows="10" style="width:1000px;height:100px" placeholder="请输入自我评价"></textarea>
  </el-form-item>
  
  
</el-form>
        </div>
        
        
        </div>
        <!-- 员工风采-->
        <div v-if="this.itemIndexzp == 2" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
          <div style="width:1000px;">
           
           <div style="color:#0762CB;font-size:26px;padding:40px 0 20px 0;">员 工 风 采</div>
          
           <div style="display:flex;justify-content:space-between;align-items:center;">
            <img src="../assets/web/p1.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/p2.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/p3.png" alt="" style="width:320px;height:210px">
          </div>
          <div style="padding:10px 0;display:flex">
            <div><img src="../assets/web/p4.png" alt="" style="width:660px;height:360px"></div>
            <div style="margin-left:20px">
              <img src="../assets/web/p5.png" alt="" style="width:320px;height:175px;">
              <img src="../assets/web/p6.png" alt=""  style="width:320px;height:175px;margin-top:6px">
            </div>
          </div>
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <img src="../assets/web/p7.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/p8.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/p9.png" alt="" style="width:320px;height:210px">
          </div>
    

</div>
        </div>
       
     
       
    
        <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:70px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
        <div>联系电话：0417-5675777</div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showtable:1,
      itemIndex: 1,
      itemIndexAbout:1,
      itemIndexVip:1,
      itemIndexba:1,
      itemIndexSup:1,
      itemIndexzp:1,
      labelPosition: 'top',
        formLabelAlign: {
          name: '',
          region: '',
          type: ''
        }
    };
  },

  methods: {
    btnshowtable(index){
      this.showtable = index;
    },
    btnClick(index) {
      this.itemIndex = index;
    },
    btnClickVip(index) {
      this.itemIndexVip = index;
    },
    btnClickba(index) {
      this.itemIndexba = index;
    },
    btnClickAbout(index) {
      this.itemIndexAbout = index;
    },
    btnClickSup(index) {
      this.itemIndexSup = index;
    },
    btnClickzp(index) {
      this.itemIndexzp= index;
    },
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.nav {
  width: 1000px;
  height: 80px;
  /* background-color: rgb(159, 81, 81); */
  color: black;
  display: flex;
  justify-content: space-between;
margin: 0 auto;
}
.banner {
  height: 80px;
  background-color: rgb(245, 228, 228);
  color: black;
  display: flex;
  justify-content: space-between;
}
.center {
  
  list-style: none;
  
  /* background-color: pink; */
}
li {
  color: rgb(24, 5, 5);
  float: left;
}
a:hover {
  /* background-color: violet; */
  color: #0762cb;
  text-decoration: underline;
}
a {
  /* 
        一定注意要把a转成行内块元素，
        如果转的是块元素那么短竖线会掉下去，
        因为块级元素是独占一行的
    */
  display: inline-block;
  padding: 0 25px;
  line-height: 80px;
  text-decoration: none;
  color: black;
}

.top-rowBanner {
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/zkImages/zk/Care for health, care for life.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height: 540px;
}
.top-rowBanner666 {
  background: #f8f8f8;
  /* padding: 3em 0 4em 0; */
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/cjx.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:260px;
}
.top-rowBanner6666 {
  background: #f8f8f8;
  /* padding: 3em 0 4em 0; */
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/join.jpg") no-repeat 0px 0px;
  background-size: 100% 100%;
  height: 540px;
}
.top-rowBanner1 {
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/size.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height: 540px;
  margin-top:35px;
}
.top-rowBanner2 {
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/indexAbout.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height: 540px;
  /* margin-top:35px; */
}
.top-rowBannerAbout {
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/1.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:100px;
  /* margin-top:35px; */
}
.top-rowBanner2222 {
  background: #f8f8f8;
  /* padding: 3em 0 4em 0; */
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: white no-repeat 0px 0px;
  background-size: 100% 100%;
  height:80px;
  border-bottom:3px dotted grey;

  /* margin-top:35px; */
}
.top-rowBanner3{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/map.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:220px;
  /* margin-top:35px; */
}
.top-rowBanner5{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/bannerVip.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.top-rowBanner5555{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/supbanner.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.top-rowBanner6{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/join.jpg") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.top-rowBanner544444{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/badsj.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.togethter{
  background: #f8f8f8;
  padding: 1em 0 1em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/toge.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.active {
  color: #0762cb;
  text-decoration: underline;
}
.activeShowHos{
 background: #0077C3;
 color:white;
 padding:10px;
  /* text-decoration: underline; */
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
}
.activeAbout {
  color: #0762cb;
  text-decoration: underline;
}
</style>